import User from '@/models/user'
import getEnv from '@/util/env'
import { LanguageOptions } from '@/classes/LanguageOptions'
export default {
  props: ['synthesisOptions'],
  name: 'SynthesisOptions',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    updateSynthesisOptions () {
      const that = this
      let found = this.getLanguageOptions().filter(function (lang) {
        return lang.id === that.synthesisOptions?.languageWithGender?.id
      })
      if (!found.length) {
        found = [this.getLanguageOptions()[0]]
      }
      this.synthesisOptions.languageWithGender.text = found[0].text
      this.synthesisOptions.url = ''
      this.synthesisOptions.prepareWithLanguageAndGender()
      const parameters = {
        message: this.synthesisOptions.message,
        language: this.synthesisOptions.language,
        gender: this.synthesisOptions.gender,
      }
      const params = new URLSearchParams(parameters)
      setTimeout(() => {
        const user = new User(JSON.parse(localStorage.getItem('user')))
        const token = user.token
        this.synthesisOptions.url = getEnv('backend_endpoint') + 'api/voice/previewPremiumSpeech?' + params + '&token=' + token
      })
    },
    // eslint-disable-next-line max-lines-per-function
    getLanguageOptions () {
      return LanguageOptions.options
    },
  },
  computed: {
    languageWithGender () {
      return this.synthesisOptions?.languageWithGender?.id || this.getLanguageOptions()[0].id
    },
  },
  created () {
    const synthesisOptions = this.synthesisOptions
    let found = this.getLanguageOptions().filter(function (lang) {
      return lang.id === synthesisOptions.language + '_' + synthesisOptions.gender
    })
    if (!found.length) {
      found = [this.getLanguageOptions()[0]]
    }

    this.synthesisOptions.languageWithGender = found[0]
    this.updateSynthesisOptions()
  },
  mounted () {
  },
}
